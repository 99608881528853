<template>
    <div class="flex" style="margin:0 0 15px 0;">
        <p class="upLoad_text">{{ selectTitle }}：
        </p>
        <div class="upload_box">
            <el-select @change="selectChange" v-model="val" placeholder="请选择">
                <el-option v-for="(item, index) in select_list" :label="item.name" :key="item.id ? item.id : index"
                    :value="item.id ? item.id : item">
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    props: {
        minWidth: Number,
        upLoad_text_align: String,
        selectTitle: String,
        select_list: Array,
    },
    data() {
        return {
            val: ''
        }
    },
    methods: {
        selectClear(){
            this.val = ''
        },
        selectChange(val) {
            this.$emit('getSelectVal', val)
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>